/* General styles */
body {
  font-family: 'Poppins', sans-serif;
  background-image: url('/public/background.png');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  padding: 20px;
  animation: fadeIn 1s ease-in-out; /* Cool fade-in effect on load */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



.header {
  background-color: #282c34; /* Your brand's primary color */
  padding: 25px;
  color: white;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  text-align: center;

  border-radius: 20px; /* Added rounded corners */
  margin: 10px; /* Add some space around the header */
}

h1 {
  font-size: 3rem;
  margin: 0;
  letter-spacing: 2px;
  font-weight: 600;
  text-transform: uppercase;
  transition: transform 0.3s ease-in-out, letter-spacing 0.3s ease-in-out;
}

h1:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  letter-spacing: 3px; /* Increase spacing for a dynamic feel */
}

h2 {
  font-size: 2rem;
  color: #444;
  margin-bottom: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: center;
  position: relative;
}

h2::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 3px;
  background-color: #017899;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

/* Style for the buttons in the header (Logout, Admin) */
.menu-right button {
  background-color: #017899;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  margin: 0 5px; /* Add space between the buttons */
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.menu-right button:hover {
  background-color: #21a1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Center the buttons horizontally */
.menu-right {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* Add space between the logo and buttons */
}

/* Search form */
.search-form {
  margin: 20px 0;
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-form input {
  padding: 10px;
  font-size: 1.1rem;
  width: 300px;
  border-radius: 30px;
  border: 2px solid #017899;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.search-form input:focus {
  border-color: #21a1f1;
  background-color: #f9f9f9;
}

.search-form button {
  padding: 10px 25px;
  font-size: 1rem;
  margin-left: 10px;
  border: none;
  background-color: #017899;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-form button:hover {
  background-color: #21a1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Slight lift on hover */
}

/* Content section */
.content {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  align-items: flex-start;
  animation: fadeIn 1s ease-in-out;
}

/* Article section styles */
.article-section {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  max-width: 800px;
  flex: 1;
  transition: all 0.3s ease;
}



/* Article content */
.article-content {
  text-align: left;
  line-height: 1.8;
  color: #003366;
  font-size: 1.1rem;
}

.article-content a {
  color: #017899;
  text-decoration: none;
  position: relative;
  transition: color 0.3s ease;
}

.article-content a::before {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #017899;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.article-content a:hover {
  color: #21a1f1;
}

.article-content a:hover::before {
  transform: scaleX(1);
}

/* Game controls */
.game-controls {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 15px;
  margin-left: 20px;
  flex-shrink: 0;
  position: sticky;
  top: 20px;
  z-index: 1;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  max-width: 300px;
}

.game-controls button,
.return-btn,
.difficulty-btn {
  padding: 10px 20px;
  margin: 15px 0;
  font-size: 1rem;
  background-color: #017899;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.game-controls button:hover,
.return-btn:hover,
.difficulty-btn:hover {
  background-color: #21a1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px); /* Slight lift */
}

/* Timer */
.timer {
  font-size: 2.5rem;
  font-weight: bold;
  color: #017899;
  margin-top: 30px;
}

/* History Section */
.history {
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.history h3 {
  font-size: 1.5rem;
  color: #017899;
  margin-bottom: 15px;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
  
}

.history h3::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background-color: #017899;
  bottom: -8px;
  left: 50%;
  transition: all 0.3s ease;
  transform: translateX(-50%);
  
}

.history ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.history ul li {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease, transform 0.3s ease;
}
.history ul li .number {
  width: 30px; /* Adjust width as needed */
  text-align: left; /* Align the number to the left */
  font-weight: bold; /* Make it visually distinct */
  margin-right: 10px; /* Add space between number and text */
}

.history ul li:hover {
  background-color: #e0f7fa;
  transform: translateX(5px); /* Move slightly to the right on hover */
}

.history ul li::before {
  content: '→'; /* Add an arrow before each list item */
  font-size: 1.2rem;
  color: #017899;
  margin-right: 10px;
  vertical-align: middle;
}


/* Rules section */
.rules-section {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 15px;
  max-width: 600px;
  margin: 30px auto;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.8s ease-in-out;
}

/* Scoreboard Section */
.scoreboard {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  padding: 30px;
  max-width: 800px;
  margin: 30px auto;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.scoreboard table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
  border-radius: 10px;
  overflow: hidden;
}

.scoreboard th, 
.scoreboard td {
  padding: 15px;
  text-align: left;
  font-size: 1.2rem;
  border-bottom: 1px solid #ccc;
}

.scoreboard th {
  background-color: #017899;
  color: white;
}

.scoreboard tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.scoreboard tbody tr:hover {
  background-color: #f1f1f1;
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Victory Section Styles */
.victory-section {
  text-align: center;
  padding: 20px;
  animation: pulse 2s infinite;
}

.victory-image {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.victory-image:hover {
  transform: rotate(3deg) scale(1.05);
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

/* Admin Panel Enhancements */
.admin-panel {
  padding: 30px;
  background-color: #f4f4f4;
  text-align: center;
  animation: fadeIn 0.1s ease-in-out;
  border-radius: 15px;
}

.admin-tools {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 30px;
}

.admin-tools button {
  padding: 15px 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.admin-tools button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.admin-panel ul {
  list-style-type: none;
  padding: 0;
  
}

.admin-panel li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: white;
}

.admin-panel li div {
  flex-grow: 1;
  padding: 0 10px;
}

.admin-panel li div.username {
  text-align: left;
  min-width: 200px; /* Ensures consistent width for usernames */
  flex: 1;
}

.admin-panel li div.email {
  text-align: center; /* Center the email */
  flex:1.5;
  flex-grow: 2; /* Allow the email field to grow more */
  min-width: 300px; /* Ensures email is properly spaced */
}

.admin-panel li label {
  text-align: right;
  padding-right: 20px;
  min-width: 100px; /* Aligns the checkbox and label properly */
}

.admin-panel li:hover {
  background-color: #444;
  transform: translateX(5px);
}

.admin-panel li button {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;
}
.admin-panel li button.add-article-btn,
.admin-panel li .assign-category button {
  background-color: #017899;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px; /* Set the same height as the dropdown */
  box-sizing: border-box; /* Ensure padding doesn't affect the height */
  margin-left: 10px; /* Space between dropdown and button */
  margin-bottom: 10px;
}
.admin-panel li button.add-article-btn:hover,
.admin-panel li .assign-category button:hover {
  background-color: #21a1f1;
}

.admin-panel li button:hover {
  background-color: darkred;
}

/* Pagination Controls */
.admin-panel .pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.admin-panel .pagination-controls button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 5px;
  cursor: pointer;
}

.admin-panel .pagination-controls button:disabled {
  background-color: #666;
  cursor: not-allowed;
}

.admin-panel .pagination-controls span {
  color: #333; /* Changed to a darker color for better visibility */
  font-size: 1rem;
  margin: 0 0px;
  
}

/* Responsive styles */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }

  .game-controls {
    margin-top: 20px;
    max-width: 100%;
  }

  .article-section {
    max-width: 100%;
  }

  .admin-tools {
    flex-direction: column;
    gap: 10px;
  }

  .scoreboard table, 
  .scoreboard th, 
  .scoreboard td {
    display: block;
    width: 100%;
    text-align: left;
  }

  .admin-panel li {
    flex-direction: column;
    text-align: center;
  }
}

/* Category input and button styles */
.add-category {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.add-category input {
  padding: 10px;
  font-size: 1rem;
  width: 250px;
  border: 2px solid #017899;
  border-radius: 30px;
  outline: none;
  transition: all 0.3s ease;
  margin-right: 10px; /* Add some space between input and button */
}

.add-category input:focus {
  border-color: #21a1f1;
  background-color: #f9f9f9;
}

.add-category button {
  padding: 10px 25px;
  font-size: 1rem;
  border: none;
  background-color: #017899;
  color: white;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-category button:hover {
  background-color: #21a1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Slight lift on hover */
}


/* Styling the leave lobby and start game buttons */
.lobby-controls button,
.start-game-section button {
  padding: 12px 25px;
  font-size: 1rem;
  background-color: #017899;
  color: white;
  border: none;
  border-radius: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.lobby-controls button:hover,
.start-game-section button:hover {
  background-color: #21a1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Slight lift */
}

/* Styling the dropdown for category selection */
.select-category {
  padding: 10px;
  font-size: 1rem;
  border-radius: 30px;
  border: 2px solid #017899;
  background-color: white;
  color: #017899;
  margin-left: 10px;
  transition: border-color 0.3s ease;
}

.select-category:hover,
.select-category:focus {
  border-color: #21a1f1;
  outline: none;
}

/* Align buttons and dropdown together */
.start-game-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.start-game-section button {
  margin-right: 15px;
}

/* Players in Lobby Section */
.lobby-players h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

/* Players in Lobby section styling */
.lobby-players {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  margin: 20px 0;
  transition: all 0.3s ease;
  position: relative;
}


.lobby-players h3 {
  font-size: 1.5rem;
  color: #017899;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: relative;
  display: inline-block;
}

.lobby-players h3::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background-color: #017899;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.lobby-players ul {
  list-style-type: none;
  padding: 0;
}

.lobby-players li {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}
.lobby-players li:hover {
  background-color: #e0f7fa;
  transform: translateX(5px);
}

.lobby-players li::before {
  content: "•";
  color: #017899;
  font-size: 1.5rem;
  margin-right: 10px;
}

.lobby-players li span {
  font-weight: bold;
  color: #017899;
  margin-right: 5px;
}

.lobby-players li .host-badge {
  background-color: #ff9800;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.9rem;
  margin-left: auto;
  font-weight: bold;
}

.lobby-players li:hover .host-badge {
  background-color: #ffb74d;
}

/* Tooltip styling for better visibility */
.article-title-with-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.article-title-with-tooltip .tooltip {
  visibility: hidden;
  width: auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.article-title-with-tooltip:hover .tooltip {
  visibility: visible;
  opacity: 1;
}


/* Lobby Info Section */
.lobby-info {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.lobby-info h3 {
  font-size: 1.8rem;
  color: #017899;
  margin-bottom: 15px;
  letter-spacing: 1px;
  position: relative;
  text-transform: uppercase;
}

.lobby-info h3::after {
  content: '';
  position: absolute;
  width: 60px;
  height: 3px;
  background-color: #017899;
  bottom: -10px;
  left: 0;
  transition: all 0.3s ease;
}



/* List of Players in Lobby */
.lobby-info ul {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
}

.lobby-info ul li {
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  font-size: 1.1rem;
  color: #333;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.lobby-info ul li:hover {
  background-color: #e0f7fa;
  transform: translateX(5px);
}

/* Add bullet icon before player's name */
.lobby-info ul li::before {
  content: "•";
  color: #017899;
  font-size: 1.5rem;
  margin-right: 10px;
}

/* Host Badge */
.lobby-info .host-badge {
  background-color: #ff9800;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 0.9rem;
  margin-left: auto;
  font-weight: bold;
}

.lobby-info ul li:hover .host-badge {
  background-color: #ffb74d;
}


/* General styles for forms */
.login-form,
.register-form {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px 40px;
  border-radius: 10px;
  max-width: 400px;
  margin: 40px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.8s ease-in-out;
}

.login-form h2,
.register-form h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  position: relative;
  letter-spacing: 2px;
}

.login-form h2::after,
.register-form h2::after {
  content: '';
  position: absolute;
  width: 80px;
  height: 3px;
  background-color: #017899;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.login-form div,
.register-form div {
  margin-bottom: 15px;
  text-align: left;
}

.login-form label,
.register-form label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
  color: #444;
}

.login-form input,
.register-form input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.login-form input:focus,
.register-form input:focus {
  border-color: #017899;
  background-color: #f9f9f9;
}

.login-form button,
.register-form button {
  padding: 10px 25px;
  background-color: #017899;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
  margin-top: 15px;
}

.login-form button:hover,
.register-form button:hover {
  background-color: #21a1f1;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 10px;
}

.success {
  color: green;
  font-size: 0.9rem;
  margin-top: 10px;
}

.article-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Light border for separation */
  transition: background-color 0.3s ease;
}

.article-line:hover {
  background-color: rgba(0, 123, 255, 0.1); /* Slight blue tint on hover */
}

.article-line strong {
  font-weight: bold;
  color: #333; /* Darker color for better contrast */
  margin-right: 10px;
}

.article-line span {
  color: #017899; /* Consistent blue for article text */
  font-size: 1.1rem; /* Slightly larger for readability */
  text-align: left;
  flex-grow: 1; /* Ensures the span takes up remaining space */
}

.article-line span:hover {
  color: #21a1f1; /* Light blue on hover */
  cursor: pointer; /* Indicates interactivity */
}

/* Optional tooltip styling for category */
.article-title-with-tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.article-title-with-tooltip .tooltip {
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.article-title-with-tooltip:hover .tooltip {
  visibility: visible;
  opacity: 1;
}




/* Ensure no horizontal scrolling occurs */
.expanded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2a2a2a;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  position: relative;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.expanded ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
  overflow-x: hidden; /* Ensure no horizontal scroll */
}

/* Individual article items */
.expanded ul li {
  padding: 10px 15px;
  background-color: #333;
  color: #f1f1f1;
  margin-bottom: 8px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.expanded ul li:hover {
  background-color: #444;
  cursor: pointer;
  transform: translateX(5px); /* Slight movement on hover without increasing width */
}

/* Ensure hover effects don't cause width increase */
.expanded ul li:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transform: translateX(0); /* No horizontal change on hover */
}

/* Button styling for "Add Article" and "Remove" */
.add-article-btn, .remove-btn {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-article-btn {
  background-color: #28a745;
  color: #fff;
  margin-right: 20px;
}

.add-article-btn:hover {
  background-color: #218838;
}

.remove-btn {
  background-color: #dc3545;
  color: white;
}

.remove-btn:hover {
  background-color: #c82333;
}

/* Scrollbar styling */
.expanded ul::-webkit-scrollbar {
  width: 8px;
}

.expanded ul::-webkit-scrollbar-track {
  background: #333;
}

.expanded ul::-webkit-scrollbar-thumb {
  background-color: #017899;
  border-radius: 10px;
}

.expanded ul::-webkit-scrollbar-thumb:hover {
  background: #21a1f1;
}

.remove-article-btn {
  background-color: red;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
  border-radius: 5px;
}

.remove-article-btn:hover {
  background-color: darkred;
}

.assign-category select {
  background-color: #f4f4f4; /* Light background color */
  color: #333; /* Dark text color for better readability */
  border: 2px solid #017899; /* Border color to match your theme */
  border-radius: 5px; /* Rounded corners for a modern look */
  padding: 8px 12px; /* Padding for comfortable interaction */
  font-size: 1rem; /* Adjust the font size for readability */
  cursor: pointer;
  outline: none; /* Remove default outline on focus */
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.assign-category select:hover {
  border-color: #21a1f1; /* Change border color on hover */
}

.assign-category select:focus {
  border-color: #21a1f1; /* Focus border color */
  box-shadow: 0 0 5px rgba(33, 161, 241, 0.5); /* Optional: Add shadow for focus */
}
